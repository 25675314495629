import React from "react";
import "./footer.css";
import { BsFacebook } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsGithub } from "react-icons/bs";
import { BsWhatsapp } from 'react-icons/bs';
import { BsMessenger } from 'react-icons/bs';
import { BsGit } from 'react-icons/bs';
import Banner from '../../assets/bannerFooter.svg';
export default function Footer() {
  return (
    <footer>
      <section className="img-banner">
        <img src={Banner} alt="Imagem banner footer" />
      </section>
      <ul className="permalinks">
        <li>
          <a href="#header">Home</a>
        </li>
        <li>
          <a href="#about">Sobre mim</a>
        </li>
        <li>
          <a href="#experience">Conhecimentos</a>
        </li>
        <li>
          <a href="#services">Serviços</a>
        </li>
        <li>
          <a href="#portifolio">Portifólio</a>
        </li>
        <li>
          <a href="#contact">Contato</a>
        </li>
      </ul>
      <div className="footer-socials">
        <a href="https://www.linkedin.com/in/elton-rocha/" target="_blank" rel="noreferrer">
          <BsLinkedin />
        </a>
        <a href="https://api.whatsapp.com/send?phone=5511999709020" target="_blank" rel="noreferrer">
          <BsWhatsapp />
        </a>
        <a href="https://www.instagram.com/eu.eltonrocha/" target="_blank" rel="noreferrer">
          <BsInstagram />
        </a>
        <a href="https://web.facebook.com/elton.rocha.1853/" target="_blank" rel="noreferrer">
          <BsFacebook />
        </a>
        <a href="https://m.me/elton.rocha.1853/" target="_blank" rel="noreferrer">
          <BsMessenger />
        </a>
        <a href="https://github.com/eltongit" target="_blank" rel="noreferrer">
          <BsGithub />
        </a>
        <a href="https://gitlab.com/eltonrochadealmeida" target="_blank" rel="noreferrer">
          <BsGit />
        </a>
      </div>
      <div className="footer-copyright">
        <small>&copy; Elton Rocha</small>
      </div>
    </footer>
  );
}