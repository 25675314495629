import React from 'react';
import './experience.css';
import { FaReact } from 'react-icons/fa';
import { FaNodeJs } from 'react-icons/fa';
import { FaGolang } from "react-icons/fa6";
import { FaPython } from "react-icons/fa";
import { SiTerraform } from 'react-icons/si';
import { SiAmazonaws } from 'react-icons/si';
import { SiDocker } from 'react-icons/si';
import { SiKubernetes } from 'react-icons/si';
import { SiWindows } from 'react-icons/si';
import { SiIbm } from 'react-icons/si';
import { SiRedhat } from 'react-icons/si';
import { SiRedhatopenshift } from 'react-icons/si';
import { SiMicrosoftazure } from 'react-icons/si';
import { SiGooglecloud } from 'react-icons/si';
import { SiCentos } from 'react-icons/si';
import { SiDebian } from 'react-icons/si';
import { SiUbuntu } from 'react-icons/si';
import { SiLinuxmint } from 'react-icons/si';
import { SiOpensuse } from 'react-icons/si';
import { SiWindowsterminal } from 'react-icons/si';
import { SiWindows11 } from 'react-icons/si';
import { SiOracle } from 'react-icons/si';
import { SiVmware } from 'react-icons/si';

export default function Experience(){
  return(
    <section id="experience" className="container-wrapper">
      <h5>Skills</h5>
      <h2>Conhecimentos e Estudos.</h2>
      <div className="experience-container container">

      <div className="experience-devops">
          <h3>Competências em DevOps</h3>
          <div className="experience-content">
            <article className="experience-details">
              <SiAmazonaws className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>AWS</h4>
              </div>
            </article>
            <article className="experience-details">
              <SiMicrosoftazure className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>Azure</h4>
              </div>
            </article>
            <article className="experience-details">
              <SiGooglecloud className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>GCP</h4>
              </div>
            </article>
            <article className="experience-details">
              <SiTerraform className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>Terraform</h4>
              </div>
            </article>
            <article className="experience-details">
              <SiRedhatopenshift className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>Openshift</h4>
              </div>
            </article>
            <article className="experience-details">
              <SiVmware className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>VMWare</h4>
              </div>
            </article>
            <article className="experience-details">
              <SiKubernetes className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>Kubernetes</h4>
              </div>
            </article>
            <article className="experience-details">
              <SiDocker className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>Docker</h4>
              </div>
            </article>
          </div>
        </div>

        <div className="experience-desenvolvimento">
          <h3>Desenvolvimentos em estudo</h3>
          <div className="experience-content">
            <article className="experience-details">
              <FaReact className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>React</h4>
              </div>
            </article>
            <article className="experience-details">
              <FaNodeJs className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>Node</h4>
              </div>
            </article>
            <article className="experience-details">
              <FaGolang className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>Go</h4>
              </div>
            </article>
            <article className="experience-details">
              <FaPython className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>Python</h4>
              </div>
            </article>
          </div>
        </div>

        <div className="experience-infraestrutura">
          <h3>Ambiente Linux e distribuições</h3>
          <div className="experience-content">
            <article className="experience-details">
              <SiRedhat className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>Redhat</h4>
              </div>
            </article>
            <article className="experience-details">
              <SiCentos className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>CentOS</h4>
              </div>
            </article>
            <article className="experience-details">
              <SiDebian className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>Debian</h4>
              </div>
            </article>
            <article className="experience-details">
              <SiUbuntu className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>Ubuntu</h4>
              </div>
            </article>
            <article className="experience-details">
              <SiLinuxmint className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>Mint</h4>
              </div>
            </article>
            <article className="experience-details">
              <SiOpensuse className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>OpenSuse</h4>
              </div>
            </article>
          </div>
        </div>

        <div className="experience-infraestrutura">
          <h3>Ambiente Windows e plataformas</h3>
          <div className="experience-content">
            <article className="experience-details">
              <SiWindows className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>Server 2012</h4>
              </div>
            </article>
            <article className="experience-details">
              <SiWindows className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>Server 2008</h4>
              </div>
            </article>
            <article className="experience-details">
              <SiWindows11 className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>Windows 11</h4>
              </div>
            </article>
            <article className="experience-details">
              <SiWindows className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>Windows 10</h4>
              </div>
            </article>
            <article className="experience-details">
              <SiWindowsterminal className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>Terminal</h4>
              </div>
            </article>
          </div>
        </div>

        <div className="experience-middleware">
          <h3>Middleware</h3>
          <div className="experience-content">
            <article className="experience-details">
              <SiIbm className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>WebSphere</h4>
              </div>
            </article>
            <article className="experience-details">
              <SiRedhat className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>Jboss</h4>
              </div>
            </article>
            <article className="experience-details">
              <SiOracle className="experience-details-icon" />
              <div>
                <h4 className='titleTech'>WebLogic</h4>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}