import React from 'react';
import Me from '../../assets/me.jpg';
import { FaAward } from 'react-icons/fa';
import { BiCodeAlt } from 'react-icons/bi';
import './about.css';
export default function About(){
  return(
    <section id="about" className="container-wrapper">
      <h5>About Me</h5>
      <h2>Sobre mim</h2>
      <div className="container about-container">
        <div className="about-me">
          <div className="about-me-img">
            <img src={Me} alt="Minha Foto" />
          </div>
        </div>
        <div className="about-content">
          <div className="about-cards">
            <article className="about-card">
              <FaAward className="about-icon"/>
              <h5>Experiência de Mercado</h5>
              <small>Mais de 10 anos</small>
            </article>
            <article className="about-card">
              <BiCodeAlt className="about-icon"/>
              <h5>Projetos Profissionais</h5>
              <small>21</small>
            </article>
            <article className="about-card">
              <BiCodeAlt className="about-icon"/>
              <h5>Projetos Pessoais</h5>
              <small>5</small>
            </article>
          </div>
          <p>
            Sou Analista de Sistemas DevOps, apaixonado por tecnologia e fascinado pelas áreas de desenvolvimento, 
            engenharia e operações. Tenho me dedicado ao aprimoramento em arquitetura e desenvolvimento, 
            estudando Infraestrutura como Código (IaC), Plataforma como Serviço (PaaS) e Software como Serviço (SaaS) 
            com ferramentas OpenSource e Enterprise, mas sempre visando proporcionar a melhor experiência possível entregando 
            um menor custo.
          </p>
          <a href="#contact" className="btn-about-me">Contato</a>
        </div>
      </div>
    </section>
  );
}