import React from 'react';
import './portifolio.css';
import Project from '../../assets/Rectangle.png';
import CaixaTem from '../../assets/caixatem.png';
import FGTS from '../../assets/fgts.png';
import OpenText from '../../assets/OpenText.png';
import MicrosoftOCS from '../../assets/MicrosoftOCS.webp';
import ECSEKS from '../../assets/ECSEKS.png';
import Jenkins_Gitlab from '../../assets/jenkins_gitlab.png';
import Starpel from '../../assets/starpel.png';
import SOS from '../../assets/sos.png';
import PortalPoupaTempo from '../../assets/poupatempo.png';
import SIM from '../../assets/sim.jpeg';
import SysAid from '../../assets/SysAid.png';
import TCIBPO from '../../assets/tcibpo.png';
import VEC from '../../assets/vec.png';
import VOIP from '../../assets/sefaz.jpg';
import Vault from '../../assets/vault.jpg';
import WikiJS from '../../assets/wikijs.png';

export default function Portifolio(){
  return(
    <section id="portifolio" className="container-wrapper">
      <h5>Projects</h5>
      <h2>Projetos Profissionais e Pessoais</h2>
      <div className="container portifolio-container">

      <article className="portifolio-item">
          <div className="portifolio-item-img">
            <img src={Vault} alt="Imagem do projeto" />
          </div>
          <h3>Vault Hashicorp (Crednovo)</h3>
          <h5 className="coffe-link">Com o intuito de passar as variaveis de sistema e segredos armazenados de forma segura e obtendo das melhores praticas do mercado este projeto foi implatando nas aplicações em Java e NodeJS usando como provisionador de deploy/release o Terraform. A configuração e execução foi feita nos ambientes de Desenvolvimento, QA, Homologação e Produção.</h5>
        </article>

      <article className="portifolio-item">
          <div className="portifolio-item-img">
            <img src={ECSEKS} alt="Imagem do projeto" />
          </div>
          <h3>Migração ECS para EKS (Crednovo)</h3>
          <h5 className="coffe-link">O objetivo deste projeto foi trocar o cluster provider dos containers que suportava os microserviços de Elastic Container Service para Elastic Kubernetes Service. trazendo assim todos os recursos que a Amazon oferece usando este metodo de cluster com a ferramente K8S nativa em seu produto. Este projeto foi implatando nas aplicações em Java e NodeJS usando como provisionador de deploy/release o Terraform. A configuração e execução foi feita nos ambientes de Desenvolvimento, QA, Homologação e Produção.</h5>
        </article>

        <article className="portifolio-item">
          <div className="portifolio-item-img">
            <img src={Jenkins_Gitlab} alt="Imagem do projeto" />
          </div>
          <h3>Migração Jenkins para GitLab (Crednovo)</h3>
          <h5 className="coffe-link">O objetivo desta migração é suprir pontos de vulnerabilidade que o Jenkins tinha nativamente e trazer para os ambientes uma ferramenta que opera com mais recursos. Este projeto foi planejado, desenvolvido e executado para as aplicações nos repositórios que, ao qual, a esteira foi construída para ser provisionada pelo Jenkins e, após a migração, sendo provisionada pela pipeline do GitLab usando os recursos da ferramentas e AWS se comunicando via terraform para deploy.</h5>
        </article>

        <article className="portifolio-item">
          <div className="portifolio-item-img">
            <img src={WikiJS} alt="Imagem do projeto" />
          </div>
          <h3>WikiJS (Crednovo)</h3>
          <h5 className="coffe-link">É um software livre que é executado em NodeJS em JavaScript que tem como objetivo a gestão do conhecimento. Neste projeto minha Squad foi responsável pela instalação e configuração e release da ferramenta. Após o release eu fui responsável por desenvolver as páginas dentro da ferramenta, documentar o processo de criação de conteúdo, ministrar treinamento de uso e manter o projeto no ar e atualizado.</h5>
        </article>

        <article className="portifolio-item">
          <div className="portifolio-item-img">
            <img src={PortalPoupaTempo} alt="Imagem do projeto" />
          </div>
          <h3>Portal PoupaTempo (Prodesp | Magna Sistemas)</h3>
          <h5 className="coffe-link">Refatoração do ambiente de produção e inclusão de recursos sobre o produto IBM desde desenvolvimento, homologação até a entrega em produção. Neste projeto o objetivo era tornar melhor o portal para acoplar o código sem bug e erros onde participei como Analista Middleware.</h5>
        </article>

        <article className="portifolio-item">
          <div className="portifolio-item-img">
            <img src={SIM} alt="Imagem do projeto" />
          </div>
          <h3>Sistema Integrado de Multas (Prodesp | Magna Sistemas)</h3>
          <h5 className="coffe-link">Aplicação desenvolvida pela Prodesp em Java e aplicada no ambiente de Middware usando produto IBM Websphere, Apache e DB2. Instalação e configuração do ambiente de infra para recebimento dos pacotes EAR e WAR do Sistema Integrado de Multas (SIM) desde o ambiente de desenvolvimento, homologação ate a entrega de produção tratando pós entrega o tunning para melhor funcionamento do sistema.</h5>
        </article>

        <article className="portifolio-item">
          <div className="portifolio-item-img">
            <img src={VEC} alt="Imagem do projeto" />
          </div>
          <h3>VEC (Prodesp | Magna Sistemas)</h3>
          <h5 className="coffe-link">Aplicação desenvolvida pela Prodesp em Java e aplicada no ambiente de Middware usando produto IBM Websphere, Apache e DB2. Instalação e configuração do ambiente de infra para recebimento dos pacotes EAR e WAR da VEC desde o ambiente de desenvolvimento, homologação ate a entrega de produção tratando pós entrega o tunning para melhor funcionamento do sistema.</h5>
        </article>

        <article className="portifolio-item">
          <div className="portifolio-item-img">
            <img src={CaixaTem} alt="Imagem do projeto" />
          </div>
          <h3>APP Caixa Tem (CEF | G&P)</h3>
          <h5 className="coffe-link">Neste projeto participei como Analista Middleware em uma Squad junto com um time de desenvolvimento, infraestrutura e dba onde foi feita a configuração, implantação do sistema via produto da IBM Mobile First e deploy do ambiente de desenvolvimento, homologação até a entrega de produção/release.</h5>
        </article>

        <article className="portifolio-item">
          <div className="portifolio-item-img">
            <img src={FGTS} alt="Imagem do projeto" />
          </div>
          <h3>APP FGTS (CEF | G&P)</h3>
          <h5 className="coffe-link">Neste projeto participei como Analista Middleware em uma Squad junto com um time de desenvolvimento, infraestrutura e dba onde foi feita a configuração, implantação do sistema via produto da IBM Mobile First e deploy do ambiente de desenvolvimento, homologação até a entrega de produção/release.</h5>
        </article>

        <article className="portifolio-item">
          <div className="portifolio-item-img">
            <img src={OpenText} alt="Imagem do projeto" />
          </div>
          <h3>Adequação de Observabilidade (OpenText)</h3>
          <h5 className="coffe-link">Participei da adequação dos alertas nos servidores UNIX Solaris realizada na Open Text que tinha como objetivo revisar todo o escopo de monitoramento criado e não criado onde Toda revisão e criação sobre a monitoração das rotinas, alertas, processos e servers foram realizados por nossa equipe.</h5>
        </article>

        <article className="portifolio-item">
          <div className="portifolio-item-img">
            <img src={OpenText} alt="Imagem do projeto" />
          </div>
          <h3>Migração de Observabilidade SP2 Alog (OpenText)</h3>
          <h5 className="coffe-link">Participei da migração realizada na Open Text que tinha como objetivo migrar os servidores alocados no site para virtualização e da automação do SO. Toda reconstrução e criação sobre o monitoramento das rotinas, alertas, processos e servers foram realizados por nossa equipe.</h5>
        </article>

        <article className="portifolio-item">
          <div className="portifolio-item-img">
            <img src={SysAid} alt="Imagem do projeto" />
          </div>
          <h3>SysAid (TCI BPO)</h3>
          <h5 className="coffe-link">Implantação da ferramenta na corporação a fim de substituir a antiga ferramenta de chamados que era acessada em aplicação de um client por um acesso via web com base em ITIL V3. Migração dos antigos incidentes e requisições de serviço da antiga ferramenta para o SYSAID.</h5>
        </article>

        <article className="portifolio-item">
          <div className="portifolio-item-img">
            <img src={TCIBPO} alt="Imagem do projeto" />
          </div>
          <h3>TCI nas Nuvens (TCI BPO)</h3>
          <h5 className="coffe-link">Participei da migração de domínio em 34 sites da empresa Grupo TCI Business Process Outsourcing onde foi centralizado todos os serviços de Active Directory em um Servidor Central onde foi configurado o serviço LDAP para integrar outras ferramentas da empresa. Ministrei treinamento para colaboradores da empresa Build Up que faz parte do Grupo TCI Business Process Outsourcing referente ao novo domínio de e-mail Google APPS onde este serviço de e-mail foi integrado com o novo domínio da corporação.</h5>
        </article>

        <article className="portifolio-item">
          <div className="portifolio-item-img">
            <img src={MicrosoftOCS} alt="Imagem do projeto" />
          </div>
          <h3>Microsoft OCS (Sefaz | G&P)</h3>
          <h5 className="coffe-link">Configurações dos servidores para funcionamento da ferramenta e implantação da ferramenta em todas as estações de trabalho da Secretaria de Fazenda do Estado de São Paulo – Osasco.</h5>
        </article>

        <article className="portifolio-item">
          <div className="portifolio-item-img">
            <img src={VOIP} alt="Imagem do projeto" />
          </div>
          <h3>VOIP (Sefaz | G&P)</h3>
          <h5 className="coffe-link">Implantação de Sistema de Telecom em Voz na Secretaria da Fazenda do Estado de São Paulo – Osasco. Instalação e configuração da parte física e lógica dos equipamentos Cisco e roteadores. Implantação do equipamento para recebimento do link E1 para o sinal de voz para rede da Sefaz em Osasco-SP.</h5>
        </article>

        <article className="portifolio-item">
          <div className="portifolio-item-img">
            <img src={Starpel} alt="Imagem do projeto" />
          </div>
          <h3>Nova Infraestrutura Lógica (Starpel)</h3>
          <h5 className="coffe-link">Projeto da Nova Infraestrutura mensurado, editado e documentado por mim conforme as necessidades e disposições da corporação, projeto indicou a refazer toda rede física e lógica da empresa para uma rede padrão. Implantado Servidor Windows Server 2003 para integrar VPN para comunicação entre sites.</h5>
        </article>

        <article className="portifolio-item">
          <div className="portifolio-item-img">
            <img src={SOS} alt="Imagem do projeto" />
          </div>
          <h3>Nova Infraestrutura Lógica (SOS Computadores)</h3>
          <h5 className="coffe-link">Projeto de Nova Rede Lógica mensurado, editado, documentado e implantado por mim conforme as necessidades e disposições da corporação, projeto indicou a implantar uma rede lógica empresarial padrão. Instalado nesta rede Servidor Windows Server 2008 R2 e habilitado os serviços de Active Directory (AD), Domain Name System (DNS), Dynamic Host Configuration Protocol (DHCP), Serviços de Informações da Internet (IIS), Servidor de Arquivos. Nesta implantação foi migrado um de banco de dados SQL 2003 para SQL 2008 instalado na nova rede.</h5>
        </article>

        <article className="portifolio-item">
          <div className="portifolio-item-img">
            <img src={Project} alt="Imagem do projeto" />
          </div>
          <h3>Em breve mais projetos!</h3>
          <h5 className="coffe-link">Em construção...</h5>
        </article>

      </div>
      {/* <h5 className="link-repos">
        <a href="https://github.com/eltongit?tab=repositories" target="_blank" rel="noreferrer">Repositórios no GitHub</a>
      </h5> */}
    </section>
  );
}