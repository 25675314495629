import React from 'react';
import './services.css';
import { BsCheck2Circle } from 'react-icons/bs';
export default function Services(){
  return(
    <section id="services" className="container-wrapper">
      <h5>Services</h5>
      <h2>Serviços</h2>
      <div className="container services-container">
        <article className="service">
          <div className="service-head">
            <h3>DevOps</h3>
          </div>
          <ul className="service-list">
            <li>
              <BsCheck2Circle className="service-list-icon"/>
              <p>
                Automação, operação, desenvolvimento e infraestrutura.</p>
            </li>
          </ul>
        </article>
        <article className="service">
          <div className="service-head">
            <h3>Arquitetura</h3>
          </div>
          <ul className="service-list">
            <li>
              <BsCheck2Circle className="service-list-icon"/>
              <p>Elaboração de projetos de sistemas e ferramentas.</p>
            </li>
          </ul>
        </article>
        <article className="service">
          <div className="service-head">
            <h3>Engenharia</h3>
          </div>
          <ul className="service-list">
            <li>
              <BsCheck2Circle className="service-list-icon"/>
              <p>Execução de projetos e sistemas sobre as ferramentas disponibilizadas para o mesmo.</p>
            </li>
          </ul>
        </article>
        <article className="service">
          <div className="service-head">
            <h3>Mentoria</h3>
          </div>
          <ul className="service-list">
            <li>
              <BsCheck2Circle className="service-list-icon"/>
              <p>Elaboração de masterclass, eventos e palestras sobre tecnologias e areas que envolvem.</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  );
}