import React from 'react';
import './header.css';
import CTA from './CTA';
import Me from '../../assets/undraw_programming_re_kg9v.svg';
import HeaderSocial from './HeaderSocials';
import Typical from 'react-typical';
export default function Header(){
  return(
    <header>
      <div data-section id="header" className="container header-container">
        <div className="header-container-info">
          <h5>Olá, tudo bem?</h5>
          <h5>Eu sou</h5>
          <h2>Elton Rocha De Almeida</h2>
          <Typical
            steps={[
              'Analista de Sistemas DevOps 💻.', 1000, 
              'Trabalho com IaC, IaaS, SaaS, PaaS e automação.', 1000,
              'Atualmente estou na área com progressão para SRE.', 1000,
              'Estudante em desenvolvimento e arquitetura.', 1000]}
            loop={Infinity}
            wrapper="p"
            className="text-light"
          />
          <CTA />
          <HeaderSocial />
        </div>
        <div className="me">
          <img src={Me} alt="Minha foto" />
        </div>
        <a href="#about" className="scroll-down">Scroll Down</a>
      </div>
    </header>
  );
}