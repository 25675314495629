import React, { useRef } from 'react';
import './contact.css';
import { MdOutlineEmail } from 'react-icons/md';
import { BsFacebook } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsWhatsapp } from 'react-icons/bs';
import { BsMessenger } from 'react-icons/bs';
import emailjs from 'emailjs-com';
import {toast} from 'react-toastify';
export default function Contact(){
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_c0doxtr', 'template_1hjkj5o', form.current, 'd-9mV4jdtRyOu6gWk');
    e.target.reset()
    toast.warning('Desculpe, eu ainda estou corringindo o envio de e-mails!');
  }

  return(
    <section id="contact" className="container-wrapper">
      <h5>Get In Touch</h5>
      <h2>Contato</h2>

      <div className="container contact-container">
        <div className="contact-options">
          <article className="contact-option">
            <MdOutlineEmail className="contact-option-img" />
            <h4>Email</h4>
            <h5>eltonrochadealmeida@gmail.com</h5>
            <a href="mailto:eltonrochadealmeida@gmail.com" target="_blank" rel="noreferrer">Envie um email</a>
          </article>
          
          <article className="contact-option">
            <BsWhatsapp className="contact-option-img" />
            <h4>WhatsApp</h4>
            <h5>+55 (11) 99970-9020</h5>
            <a href="https://api.whatsapp.com/send?phone=5511999709020" target="_blank" rel="noreferrer">Entre em contato pelo WhatsApp</a>
          </article>

          <article className="contact-option">
            <BsLinkedin className="contact-option-img" />
            <h4>Linkedin</h4>
            <h5>Elton Rocha</h5>
            <a href="https://www.linkedin.com/in/elton-rocha/" target="_blank" rel="noreferrer">Envie uma mesagem</a>
          </article>

          <article className="contact-option">
            <BsInstagram className="contact-option-img" />
            <h4>Instagram</h4>
            <h5>Elton Rocha</h5>
            <a href="https://www.instagram.com/eu.eltonrocha/" target="_blank" rel="noreferrer">Envie uma mesagem</a>
          </article>

          <article className="contact-option">
            <BsFacebook className="contact-option-img" />
            <h4>Facebook</h4>
            <h5>Elton Rocha</h5>
            <a href="https://www.facebook.com/elton.rocha.1853" target="_blank" rel="noreferrer">Envie uma mesagem</a>
          </article>

          <article className="contact-option">
            <BsMessenger className="contact-option-img" />
            <h4>Messenger</h4>
            <h5>Elton Rocha</h5>
            <a href="https://m.me/elton.rocha.1853/" target="_blank" rel="noreferrer">Envie uma mesagem</a>
          </article>

        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Nome Completo" required />
          <input type="email" name="email" placeholder="Email" required />
          <textarea name="message" rows="7" placeholder="Escreva para mim!" required></textarea>
          <button type="submit" className="btn-submit-form">Enviar</button>
        </form>
      </div>
    </section>
  );
}